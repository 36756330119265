import React, { useState, useEffect } from 'react';
import SearchForm from './SearchForm';
import ResultsDisplay from './ResultsDisplay';
import { searchGifts, fetchDefaultDeals } from '../services/api';

const GiftFinder = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    age: '',
    gender: '',
    region: '',
    occasion: '',
    description: ''
  });
  const [recommendations, setRecommendations] = useState([]);
  const [defaultProducts, setDefaultProducts] = useState([]);

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await fetchDefaultDeals();

        // Ensure the response contains necessary fields
        const formattedProducts = response.data.products.map(product => ({
          ...product,
          category: product.category || 'Uncategorized', // Fallback if category is missing
          price: product.price || 'Price unavailable' // Fallback if price is missing
        }));

        setDefaultProducts(formattedProducts);
      } catch (err) {
        console.error('Failed to fetch default deals:', err);
      }
    };

    fetchDeals();
  }, []);

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    if (error) setError(null);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await searchGifts(formData);
      if (!response?.data?.recommendations) {
        throw new Error('Invalid response format');
      }

      // Ensure the response contains necessary fields
      const formattedRecommendations = response.data.recommendations.map(product => ({
        ...product,
        category: product.category || 'Uncategorized', // Fallback if category is missing
        price: product.price || 'Price unavailable' // Fallback if price is missing
      }));

      setRecommendations(formattedRecommendations);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to generate recommendations.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <div className="w-full bg-gradient-to-r from-rose-200 to-pink-300 h-40 flex items-center justify-center">
        <img src="/banner.svg" alt="GiftHaven" className="h-24" />
      </div>

      <div className="flex flex-1">
        <div className="w-80 bg-white shadow-lg p-6 border-r border-gray-200">
          <img
            src="/walle.png"
            alt="Personalized Gift Finder AI logo"
            className="mb-4"
          />
          <h2 className="text-lg font-semibold mb-2">Gift Finder</h2>
          <p className="text-sm font-light text-gray-600 italic" style={{ fontFamily: 'Dancing Script, cursive' }}>
            Let AI help you with Personalized Gift Suggestions
          </p>
          <hr className="my-4 border-t-2 border-gray-300" />
          <br />
          <SearchForm
            formData={formData}
            onInputChange={handleInputChange}
            onSubmit={handleSubmit}
            loading={loading}
            error={error}
          />
        </div>

        <div className="flex-1 p-6">
          {recommendations.length > 0 ? (
            <ResultsDisplay recommendations={recommendations} />
          ) : (
            <div>
              <h2 className="text-xl font-bold text-gray-700 mb-4">Check Best Deals Today</h2>
              <ResultsDisplay recommendations={defaultProducts} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GiftFinder;
