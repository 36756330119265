import React from 'react';

const SearchForm = ({ formData = {}, onInputChange = () => {}, onSubmit, loading, error }) => {
  const isFormIncomplete = !formData.age || !formData.gender || !formData.region || 
                          !formData.occasion || !formData.description;

  return (
    <div className="space-y-4">
      {error && (
        <div className="bg-red-100 text-red-700 p-3 rounded-md">
          {error}
        </div>
      )}

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Age</label>
          <input
            type="number"
            className="w-full rounded-md border-gray-300 shadow-sm"
            placeholder="Enter age"
            value={formData.age}
            onChange={(e) => onInputChange('age', e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Gender</label>
          <select
            className="w-full rounded-md border-gray-300 shadow-sm"
            value={formData.gender}
            onChange={(e) => onInputChange('gender', e.target.value)}
          >
            <option value="">Select gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Region</label>
        <input
          type="text"
          className="w-full rounded-md border-gray-300 shadow-sm"
          placeholder="Enter region/country"
          value={formData.region}
          onChange={(e) => onInputChange('region', e.target.value)}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Occasion</label>
        <input
          type="text"
          className="w-full rounded-md border-gray-300 shadow-sm"
          placeholder="Enter occasion (e.g., Birthday, Christmas)"
          value={formData.occasion}
          onChange={(e) => onInputChange('occasion', e.target.value)}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Additional Details</label>
        <textarea
          className="w-full rounded-md border-gray-300 shadow-sm"
          placeholder="Enter any additional details about the person or preferences"
          value={formData.description}
          onChange={(e) => onInputChange('description', e.target.value)}
          rows={4}
        />
      </div>

      <button
        onClick={onSubmit}
        disabled={isFormIncomplete || loading}
        className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-200 disabled:bg-blue-300"
      >
        {loading ? 'Generating Recommendations...' : 'Find Gift Recommendations'}
      </button>
    </div>
  );
};

export default SearchForm;
