import React, { useState } from 'react';

const ResultsDisplay = ({ recommendations }) => {
  const [selectedCategory, setSelectedCategory] = useState('All'); // State for category filter

  // Function to filter recommendations by category
  const filterByCategory = (products, category) => {
    if (category === 'All') return products;
    return products.filter(product => product.category === category);
  };

  // Function to check if a URL is from Amazon
  const isAmazonLink = (url) => {
    try {
      const urlObj = new URL(url);
      return urlObj.hostname.includes('amazon.');
    } catch {
      return false;
    }
  };

  // Function to convert regular Amazon URL to an affiliate URL
  const getAffiliateLink = (url) => {
    try {
      if (!isAmazonLink(url)) {
        return url; // Return as-is if not an Amazon URL
      }

      const urlObj = new URL(url);

      // Extract ASIN if present (Amazon Standard Identification Number)
      const pathSegments = urlObj.pathname.split('/');
      const asinIndex = pathSegments.indexOf('dp') + 1; // '/dp/<ASIN>'
      const asin = pathSegments[asinIndex] || null;

      if (asin) {
        // Build the affiliate URL with ASIN
        return `https://${urlObj.hostname}/dp/${asin}?tag=present061-20`;
      }

      // If no ASIN found, fallback to original URL with affiliate tag
      const baseUrl = url.split('?')[0]; // Remove existing query parameters
      return `${baseUrl}?tag=present061-20`;
    } catch (error) {
      console.error('Error converting to affiliate link:', error.message);
      return url; // Return the original URL in case of failure
    }
  };

  // Filter recommendations based on the selected category
  const filteredRecommendations = filterByCategory(recommendations, selectedCategory);

  return (
    <div>
      {/* Category Filter Dropdown */}
      <div className="mb-4">
        <label htmlFor="category-filter" className="block text-sm font-medium text-gray-700">
          Filter by Category
        </label>
        <select
          id="category-filter"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="All">All</option>
          {[...new Set(recommendations.map(product => product.category))].map(category => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      {/* Product Display Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredRecommendations.map((gift, index) => {
          const productLink = isAmazonLink(gift.link)
            ? getAffiliateLink(gift.link)
            : gift.link;

          return (
            <div
              key={index}
              className="p-4 border border-gray-200 rounded-lg flex flex-col justify-between h-80 bg-white shadow-sm"
            >
              <div>
                <h3 className="text-lg font-semibold mb-2">{gift.name}</h3>
                <p className="text-gray-600 mb-2">{gift.category}</p>
                <p className="text-green-600 font-bold mb-2">{gift.price}</p>
                <p className="text-gray-500 text-sm mb-4">{gift.description}</p>
              </div>
              <div className="mt-auto">
                <a
                  href={productLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition"
                >
                  Me Likey!
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ResultsDisplay;
