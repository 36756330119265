import axios from 'axios';

const DEFAULT_API_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3001'  // Changed to 3001 to match your backend port
  : process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: DEFAULT_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false  // Changed to false since your CORS config doesn't include credentials
});

// Add request interceptor for debugging
api.interceptors.request.use(
  config => {
    console.log('API Request:', {
      url: config.url,
      method: config.method,
      data: config.data
    });
    return config;
  },
  error => {
    console.error('API Request Error:', error);
    return Promise.reject(error);
  }
);

export const searchGifts = async (searchData) => {
  try {
    console.log('Sending search request with data:', searchData);
    const response = await api.post('/api/search', searchData);
    console.log('Received response:', response.data);
    return response;
  } catch (error) {
    console.error('Search gifts error:', error);
    if (error.response) {
      throw new Error(error.response.data.error || 'Server error occurred');
    } else if (error.request) {
      throw new Error('Cannot connect to server. Please ensure the server is running.');
    } else {
      throw new Error('An error occurred while setting up the request.');
    }
  }
};

// Health check function to test connectivity
export const checkApiHealth = async () => {
  try {
    const response = await api.get('/health');
    return response.data;
  } catch (error) {
    throw new Error('API health check failed');
  }
};

export const fetchDefaultDeals = async () => {
  return await axios.get(`${DEFAULT_API_URL}/api/defaultdeals`);
};