import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await axios.get('/api/read');  // Assuming the backend is running on the same server
        setPosts(response.data);
      } catch (err) {
        setError('Failed to load blog posts');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h1 className="text-center text-3xl font-bold mt-10">Get Inspired</h1>

      <div className="blog-posts mt-6">
        {posts.length > 0 ? (
          posts.map(post => (
            <div key={post._id} className="blog-post bg-white shadow-md p-6 rounded-lg mb-6">
              <h2 className="text-xl font-semibold">{post.title}</h2>
              <p className="text-sm text-gray-500">By {post.author} on {new Date(post.date).toLocaleDateString()}</p>
              <div className="content mt-4">
                <p>{post.content.substring(0, 200)}...</p> {/* Display the first 200 characters */}
              </div>
              <a href={`/read/${post._id}`} className="text-blue-500 hover:underline mt-2 inline-block">Read More</a>
            </div>
          ))
        ) : (
          <p>No blog posts available.</p>
        )}
      </div>
    </div>
  );
};

export default BlogPage;
